import i18n from '@/translation/i18n';
import { storeToRefs } from 'pinia';
import { int } from '@kitware/vtk.js/types';
import { useDICOMStore } from '../store/datasets-dicom';
import { useModuleStore } from '../store/modules';
import { getDemoFiles, isDemo } from '../auth/demo';
import { openDicomFiles } from '../io/upload';
import { useTreatmentStore } from '../store/treatments';
import { getFirstRunTourDoneCookie } from './cookie';
import { applyCorrection } from '../composables/implantDisplay';

function unloadScene() {
  const dicomStore = useDICOMStore();
  const treatmentStore = useTreatmentStore();

  if (Object.keys(dicomStore.patientInfo).length) {
    const patients = Object.entries(dicomStore.patientInfo).map(([key, info]) => ({
      key,
      info,
    }));
    dicomStore.deletePatient(patients[0].key);
    treatmentStore.resetTreatments();
  }
}

function closeTour() {
  const moduleStore = useModuleStore();
  const { selectedModuleIndex } = storeToRefs(moduleStore);

  // Unload data
  unloadScene();
  if (getFirstRunTourDoneCookie()) {
    // The tab is closed at end of tour, except if it is the first tour of the app
    window.close();
  }
  selectedModuleIndex.value = 0
}

const demoDataTreatments = {
  "24": {
    "rod": {
      "origin": [23.5902079082176, 21.762542543670218, 26.110598521507903],
      "planes": {
        "4": {
          "normal": [
            0.9608121267243147, -0.2081681866217234, 0.18304661487753388
          ],
          "viewUp": [
            -0.17988185693514055, 0.034199628174440326, 0.9830935372477514
          ]
        },
        "5": {
          "normal": [
            -0.2530190309681891, -0.9283329160069821, 0.2723588937888609
          ],
          "viewUp": [
            -2.3418766925686896e-17, 0.2815191608767306, 0.9595556065488146
          ]
        },
        "6": {
          "normal": [
            0.11323174072414235, -0.30800000509187786, -0.9446240361942869
          ],
          "viewUp": [
            -0.0016823677673537716, -0.9507966840353892, 0.3098109669879421
          ]
        }
      },
      "size": "large"
    },
    "implant":
      {
        "model":
        {
          "manufacturer": "IBS",
          "model": "Magicore",
          "reference": "452M4011",
          "path": "implants/files/IBS/Magicore/452M4011.vtp",
          "diameter": 4,
          "length": 11,
          "cuff": 2
        },
        "origin":[23.5902079082176, 21.762542543670218, 26.110598521507903],
        "planes":
        {
          "4": {
            "normal": [
              0.9608121267243147, -0.2081681866217234, 0.18304661487753388
            ],
            "viewUp": [
              -0.17988185693514055, 0.034199628174440326, 0.9830935372477514
            ]
          },
          "5": {
            "normal": [
              -0.2530190309681891, -0.9283329160069821, 0.2723588937888609
            ],
            "viewUp": [
              -2.3418766925686896e-17, 0.2815191608767306, 0.9595556065488146
            ]
          },
          "6": {
            "normal": [
              0.11323174072414235, -0.30800000509187786, -0.9446240361942869
            ],
            "viewUp": [
              -0.0016823677673537716, -0.9507966840353892, 0.3098109669879421
            ]
          }
        },
        "inputSource":
        {
          "views2D":
          {
            "4": null,
            "5": null,
            "6": null
          },
          "transformFilter": null
        }
      },
    "correction": {
      "corrector": {
        "translations": { "Coronal": 0, "Sagittal": 0 },
        "rotations": { "Coronal": 0, "Sagittal": 0 },
        "type": "Classic"
      },
      "indexOrientation": null,
      "drilling": 0,
      "reference": null
    },
    "report": { "bone": null, "gum": null, "drilling": null, "other": null }
  }
}

const demoDataTreatmentsWithDrilling = {
  "24": {
    ...demoDataTreatments["24"],
    "correction": {
      "corrector": {
        "translations": { "Coronal": 0, "Sagittal": 0 },
        "rotations": { "Coronal": 0, "Sagittal": 0 },
        "type": "Classic"
      },
      "indexOrientation": null,
      "drilling": 4.525,
      "reference": null
    },
  }
}


const demoDataTreatmentsWithCorrectionAndDrilling = {
  "24": {
    ...demoDataTreatments["24"],
    "correction": {
      "corrector": {
        "translations": { "Coronal": 0.7, "Sagittal": 0 },
        "rotations": { "Coronal": 0, "Sagittal": 0 },
        "type": "Classic"
      },
      "indexOrientation": null,
      "drilling": 4.525,
      "reference": null
    },
  }
}

export function getAccuratorAppTourSteps() {
  const moduleStore = useModuleStore();
  const { selectedModuleIndex } = storeToRefs(moduleStore);

  function setActiveStep(step: int) {
    return new Promise((resolve) => {
      selectedModuleIndex.value = step;
      setTimeout(() => resolve(step), 100);
    });
  }

  // Make sure the index of steps is ok in `onNextStep` and `onPreviousStep`
  // when adding new steps here.
  return [
    { // 0
      target: '#app-bar',
      content: i18n.t('appTour.start'),
      params: {
        placement: 'bottom'
      },
    },
    { // 1
      target: '#import-tab-module',
      content: i18n.t('appTour.step0'),
      params: {
        placement: 'right'
      },
      before: () => setActiveStep(0),
    },
    { // 2
      target: '#dicomFileInput',
      content: i18n.t('appTour.step1'),
      params: {
        placement: 'bottom',
        highlight: true
      },
    },
    { // 3
      target: '#appTour-step2',
      content: i18n.t('appTour.step2'),
      params: {
        placement: 'bottom',
        highlight: true
      },
      before: () => new Promise((resolve) => {
        setActiveStep(0).then(() => {
          if (isDemo()) {
            // In case we are using the demo app, data is already loaded
            resolve("foo");
          } else {
            // // Unload data and load demo data
            unloadScene();
            
            // Load demo data
            getDemoFiles().then(openDicomFiles).then(() => {
              resolve("foo");
            });
          }
        })
      }),
    },
    { // 4
      target: '#rod-tab-module',
      content: i18n.t('appTour.step3'),
      params: {
        placement: 'right'
      },
      before: () => setActiveStep(1),
    },
    { // 5
      target: '#apptour-step-4',
      content: i18n.t('appTour.step4'),
      params: {
        placement: 'right'
      },
      before: () => new Promise((resolve) => {
        const treatmentStore = useTreatmentStore();
        const { selectedTooth } = storeToRefs(treatmentStore);
        treatmentStore.setTreatments(demoDataTreatments);
        selectedTooth.value = 24;
        setTimeout(() => {
          resolve("foo"); // Needs to return a promise
        },(25));
      }),
    },
    { // 6
      target: '#apptour-step-5',
      content: i18n.t('appTour.step5'),
      params: {
        placement: 'right'
      },
    },
    { // 7
      target: '#apptour-step-6',
      content: i18n.t('appTour.step6'),
      params: {
        placement: 'left'
      }
    },
    { // 8
      target: '#apptour-step-7',
      content: i18n.t('appTour.step7'),
      params: {
        placement: 'top'
      },
      before: () => setActiveStep(1),
    },
    { // 9
      target: '#implants-tab-module',
      content: i18n.t('appTour.step8'),
      params: {
        placement: 'right',
        highlight: true
      },
      before: () => setActiveStep(2),
    },
    { // 10
      target: '#apptour-step-9',
      content: i18n.t('appTour.step9'),
      params: {
        placement: 'top'
      },
      before: () => new Promise((resolve) => {
        const treatmentStore = useTreatmentStore();
        treatmentStore.setTreatments(demoDataTreatmentsWithDrilling);
        applyCorrection();
        setTimeout(() => {
          resolve("foo"); // Needs to return a promise
        },(25));
      }),
    },
    { // 11
      target: '#threed-view',
      content: i18n.t('appTour.step9-1'),
      params: {
        placement: 'left',
        highlight: true
      }
    },
    { // 12
      target: '#apptour-step-10',
      content: i18n.t('appTour.step10'),
      params: {
        placement: 'right'
      },
      before: () => new Promise((resolve) => {
        const treatmentStore = useTreatmentStore();
        treatmentStore.setTreatments(demoDataTreatmentsWithCorrectionAndDrilling);
        applyCorrection();
        setTimeout(() => {
          resolve("foo"); // Needs to return a promise
        },(25));
      }),
    },
    { // 13
      target: '#apptour-step-11',
      content: i18n.t('appTour.step11'),
      params: {
        placement: 'bottom'
      },
      before: () => setActiveStep(2),
    },
    { // 14
      target: '#export-tab-module',
      content: i18n.t('appTour.step12'),
      params: {
        placement: 'right',
        highlight: true
      },
      before: () => setActiveStep(3),
    },
    { // 15
      target: '#apptour-step-13',
      content: i18n.t('appTour.step13'),
      params: {
        placement: 'top'
      }
    },
  ];
}

export function getAccuratorAppTourOptions() {
  return {
    useKeyboardNavigation: true,
    labels: {
      buttonSkip: i18n.t('appTour.skip'),
      buttonPrevious: i18n.t('appTour.previous'),
      buttonNext: i18n.t('appTour.next'),
      buttonStop: i18n.t('appTour.stop')
    }
  };
}

export function getAccuratorAppTourCallbacks() {
  return {
    onSkip: () => {
      console.log('onSkip');
      closeTour();
    },
    onFinish: () => {
      console.log('onFinish');
      closeTour();
    },
    onStop: () => {
      console.log('onStop');
      closeTour();
    },
  };
}
